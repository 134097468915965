import React from 'react';
import './index.css';
/**
 * 心跳
 *
 * @author Yc
 * @since 2024/5/11 2:58
 */
const Index = () =>
  <div className="loading">
    <svg width="64px" height="48px">
      <polyline points="0.157 23.954, 14 23.954, 21.843 48, 43 0, 50 24, 64 24" id="back"></polyline>
      <polyline points="0.157 23.954, 14 23.954, 21.843 48, 43 0, 50 24, 64 24" id="front"></polyline>
    </svg>
  </div>

export default Index;