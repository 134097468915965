import {ImageViewer} from "antd-mobile";
import './index.css'

const WaterfallFlow = ({images,year}) => (
  <div
    className="masonry"
    onClick={(e) => {
      if (e.target.tagName === 'IMG')
        ImageViewer.Multi.show({
          images: images.map(item => `./img/${year}/${item}.png`),
          defaultIndex:images.map(item => `${item}.png`).indexOf(e.target.src.split(`${year}thumbnails/`)[1])
        })
    }}
  >
    {images.map((item, index) => (
      <div key={index} className="masonry-item">
        {/* 渲染内容 */}
        <img src={`./img/${year}thumbnails/${item}.png`} alt={item} style={{ width: '100%' }} loading="lazy" />
      </div>
    ))}
  </div>
);

export default WaterfallFlow;