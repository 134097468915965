import './App.css';
import WaterfallFlow from "./components/WaterfallFlow";
import Loader from "./components/Loader";
import {Input, Popover} from "antd-mobile";
import {useEffect, useRef, useState} from "react";

const images2019 = ['1', '2', '3', '4', '5', '6', '7', '8', 'img', 'img_1', 'img_2', 'img_3', 'img_4', 'img_5', 'img_6', 'img_7', 'img_8', 'img_9', 'img_10', 'img_11', 'img_12',]
const images2020 = ['img', 'img_1', 'img_2', 'img_3', 'img_4', 'img_5', 'img_6', 'img_7', 'img_8', 'img_9', 'img_10', 'img_11', 'img_12', 'img_13', 'img_14', 'img_15', 'img_16',]
const images2021 = ['img', 'img_1', 'img_2', 'img_3', 'img_4', 'img_5', 'img_6', 'img_7', 'img_8', 'img_9', 'img_10', 'img_11', 'img_12', 'img_13', 'img_14', 'img_15', 'img_16', 'img_17',]
const images2022 = ['img', 'img_0','img_1', 'img_2', 'img_3', 'img_4', 'img_5', 'img_6', 'img_7', 'img_8', 'img_9', 'img_10']
const images2023 = ['img', 'img_1', 'img_2', 'img_3', 'img_4', 'img_5', 'img_6', 'img_7', 'img_8', 'img_9', 'img_10', 'img_11', 'img_12', 'img_13', 'img_14', 'img_15', 'img_16', 'img_17','img_18',]
const images2024 = ['img', 'img_1', 'img_2', 'img_3', 'img_4',]

let v;  // State总是和普通变量一起用就是会有问题 还是直接多定义一个 visible
function App() {
  const [visible, setVisible] = useState('block')  // 控制输入密码
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false); // 是否已滚动到底部
  const audioRef1 = useRef();     // 用于存储音乐元素的引用
  const audioRef2 = useRef();     // 用于存储音乐元素的引用
  const lazyImageRef = useRef();  // 用于存储图片元素的引用

  // 监控滚动事件到底部
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  /**
   * 滚动事件处理函数 就成功执行一次就销毁
   *
   * @author Yc
   * @since 2024/5/12 0:48
   */
  const handleScroll = () => {
    const isBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
    if (isBottom && !hasScrolledToBottom && v) {
      setHasScrolledToBottom(true);
      audioRef1.current.pause(); // 暂停第一首音乐
      audioRef2.current.play();  // 播放第二首音乐
      audioRef2.current.loop = true; // 设置第二首音乐循环播放
      window.removeEventListener('scroll', handleScroll); // 停止监听滚动事件
    }
  };


  return (
    <>
      {/*全屏弹窗要求输入密码*/}
      <div style={{display:visible, position:'fixed', top:0, left:0, right:0, bottom:0, background:'linear-gradient(116deg, rgb(249, 228, 228) 0%, rgb(175, 205, 204) 100%)', zIndex:999,overflow:'hidden'}}>
        💕温馨提示. 图片较多,请耐心等待图片慢慢加载.

        <Input
          type={'password'}
          placeholder={'请输6位密码'}
          style={{marginTop:'40vh',border:'#dbabab 2px solid',width:'50%',marginLeft:'25%'}}
          onChange={(e) => {
            if (e === '245556') {
              setVisible('none')
              v = true
              audioRef1?.current?.play()
            }
          }}
        />
      </div>

      <audio ref={audioRef1} src="./mp3/hdlSrkl.mp3">
        您的浏览器不支持 audio 元素。
      </audio>
      <audio ref={audioRef2} src="./mp3/srkl.mp3">
      您的浏览器不支持 audio 元素。
    </audio>

      <div style={{minHeight:'100vh'}}>
        <h1 style={{textAlign:'center'}}>2019</h1>
        <div style={{margin:8}}>
          <p style={{textIndent: '2em'}}>这是我们相遇的第一年. 虽然有点特别. 但是特别的爱留给特别的你嘛 </p>
        </div>
        <WaterfallFlow images={images2019} year={2019}/>
      </div>


      <hr/>
      <hr/>
      <div style={{minHeight:'99vh'}}>
        <h1 style={{textAlign:'center'}}>2020</h1>
        <div style={{margin:8}}>
          <p style={{textIndent: '2em'}}>这年新冠大爆发的一年. 虽然疫情隔绝在家里但是见面也变得更加欢喜. 这一年. 也是屁屁从中分变到刘海的一年是改变的一年.  </p>
        </div>
        <WaterfallFlow images={images2020} year={2020}/>
      </div>

      <hr/>
      <hr/>
      <div style={{minHeight:'99vh'}}>
        <h1 style={{textAlign:'center'}}>2021</h1>
        <div style={{margin:8}}>
          <p style={{textIndent: '2em'}}>这一年我大专毕业了,虽然没有毕业照. PP法考也过了,备考时聊天都变得少了. </p>
        </div>
        <WaterfallFlow images={images2021} year={2021}/>
      </div>

      <hr/>
      <hr/>
      <div style={{minHeight:'60vh'}}>
        <h1 style={{textAlign:'center'}}>2022</h1>
        <div style={{margin:8}}>
          <p style={{textIndent: '2em'}}>这一年我屁屁毕业了,但是毕业照屁屁不叫我去现场,哼。这年PP也考了很多次公.这年我插本成功上了松田</p>
        </div>
        <WaterfallFlow images={images2022} year={2022}/>
      </div>

      <hr/>
      <hr/>
      <div style={{minHeight:'99vh'}}>
        <h1 style={{textAlign:'center'}}>2023</h1>
        <div style={{margin:8}}>
          <p style={{textIndent: '2em'}}>这一年我屁屁算考上"公"了,但是也还在继续努力。这一年我又开始实习了</p>
        </div>
        <WaterfallFlow images={images2023} year={2023}/>
      </div>

      <hr/>
      <hr/>
      <div style={{minHeight:'40vh'}}>
        <h1 style={{textAlign:'center'}}>2024</h1>
        <div style={{margin:8}}>
          <p style={{textIndent: '2em'}}>今年我们的故事还在继续的书写...</p>
        </div>
        <WaterfallFlow images={images2024} year={2024}/>
      </div>

      <div style={{margin:18,textAlign:'center'}}><Loader /></div>

      {/*生日正题*/}
      <h1 style={{textAlign:"center",color:"#ab7a12"}}>
        🎊祝宝贝屁屁生日快乐🎂✨
      </h1>
      <Popover
        content='身体健康,好运滚滚来,福星高照,逢考必过'
        trigger='click'
        placement='top'
        defaultVisible
      >

        <img src={`./img/soul.png`} alt={'soul'} loading={'lazy'} ref={lazyImageRef} width={'100%'}/>
      </Popover>
    </>
  );
}

export default App;
